@import "~bootstrap/scss/bootstrap.scss";

body {
  position: relative;
  min-height: 100vh;
  //background: linear-gradient(#1d8dee, #1dee74);
  //animation: filterAnimation 10s linear infinite;
}
body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background: linear-gradient(#ffbe19, #2ba6f2);
}
#root {
  position: relative;
  z-index: 10;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  background: linear-gradient(#1d8dee, #1dee74);
  animation: filterAnimation 10s linear infinite;
  &::after {
    background: linear-gradient(#ffbe19, #2ba6f2);
  }
}

.content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
h1 {
  margin: 0;
  font-size: 4rem;
}
.comic {
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif;
}

.gift-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-height: 30vh;
  .img-gift {
    aspect-ratio: 1/1;
    object-fit: fill;
    flex: 1;
    max-height: 100%;
  }
}

@keyframes filterAnimation {
  0%,
  100% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(360deg);
  }
}
